import i18n from '@/i18n'
import Store from '@/store'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
import ReportHeading from '@/Utils/report-head-lic-o'
import { dateFormat } from '@/Utils/fliter'

const exportPdfDetails = async (baseUrl, uri, orgId, reportTitle, vm) => {
  try {
    Store.commit('mutateCommonProperties', {
      loading: true
    })
    if (i18n.locale === 'bn') {
      pdfMake.vfs = pdfFontsBn.pdfMake.vfs
    } else {
        pdfMake.vfs = pdfFontsEn.pdfMake.vfs
    }
    const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
    const pdfContent = [
      {
        columns: reportHeadData.reportHeadColumn
      },
      { text: reportHeadData.orgName, style: 'org', alignment: 'center' },
      { text: reportHeadData.projectName, style: 'address', alignment: 'center' },
      { text: reportHeadData.address, style: 'address', alignment: 'center' },
      { text: reportTitle, style: 'hh', alignment: 'center' }
    ]
      // pdfContent.push({ text: reportTitle, style: 'header2', alignment: 'center', decoration: 'underline' })
      pdfContent.push({ text: '', style: 'fertilizer' })
      const search = vm.search
      const currentLocale = vm.currentLocale
      const grandTotalInfo = vm.grandTotalInfo
      // const totalInfo = vm.totalInfo
      const allRowsHead = [
        [
          { text: vm.$t('user_role.organization') + ' : ' + (search.org_id ? vm.getOrganizationName(search.org_id) : vm.$t('globalTrans.all')), alignment: 'left', style: 'th', bold: true },
          { text: vm.$t('globalTrans.year') + ' : ' + (search.year ? vm.$n(search.year, { useGrouping: false }) : vm.$t('globalTrans.all')), alignment: 'left', style: 'th', bold: true }
        ],
        [
          { text: vm.$t('globalTrans.from_date') + ' : ' + dateFormat(search.from_date), alignment: 'left', style: 'th', bold: true },
          { text: vm.$t('globalTrans.to_date') + ' : ' + dateFormat(search.to_date), alignment: 'left', style: 'th', bold: true }
        ],
        [
          { text: vm.$t('globalTrans.type') + ' : ' + (search.application_type ? vm.getApplicationTypeName(search.application_type) : vm.$t('globalTrans.all')), alignment: 'left', style: 'th', bold: true },
          { text: vm.$t('org_pro.service_namel') + ' : ' + (search.paid_type ? vm.getPaymentTypeName(search.paid_type) : vm.$t('globalTrans.all')), alignment: 'left', style: 'th', bold: true }
        ]
      ]
      pdfContent.push({
        table: {
          widths: ['50%', '50%'],
          body: allRowsHead
        },
        layout: {
          hLineWidth: function (i, node) {
            return 0
          },
          vLineWidth: function (i, node) {
            return 0
          }
        }
      })
      if (vm.listData.length) {
        vm.listData.forEach((serviceItem, serviceIndex) => {
          pdfContent.push({ text: vm.getServiceName(serviceItem.service_id), style: 'header1', alignment: 'left', bold: true })
          const allRows = []
          const tableHead = [
            { text: vm.$t('globalTrans.sl_no'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('globalTrans.month'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('li_step.mode_of_collateral_deposit'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('li_step.pay_order_slip_no'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('li_step.deposit_date'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('li_step.security_providing_institute'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('li_step.security_deposited_amount'), style: 'th', alignment: 'center', bold: true }
          ]
          allRows.push(tableHead)
          if (serviceItem.month_info.length) {
            serviceItem.month_info.forEach((monthItem, monthItemIndex) => {
              monthItem.application_info.forEach((appItem, appItemIndex) => {
                const marginTop = monthItem.total_rows * 8
                const margin = [3, marginTop, 3, 3]
                allRows.push([
                  { text: vm.$n(monthItemIndex + 1, { useGrouping: false }), style: 'td', alignment: 'center', rowSpan: appItemIndex === 0 ? monthItem.total_rows : null, margin: margin },
                  { text: vm.getMonthName(monthItem.month), style: 'td', alignment: 'center', rowSpan: appItemIndex === 0 ? monthItem.total_rows : null, margin: margin },
                  { text: vm.getPaymentTypeName(appItem.paid_type), style: 'td', alignment: 'center' },
                  { text: appItem.paid_type === 1 ? appItem.transaction_no : vm.$n(appItem.challan_no, { useGrouping: false }), style: 'td', alignment: 'center' },
                  { text: dateFormat(appItem.payment_date), style: 'td', alignment: 'center' },
                  { text: currentLocale === 'en' ? appItem.organization_name_en : appItem.organization_name_bn, style: 'td', alignment: 'center' },
                  { text: vm.$n(appItem.amount), style: 'td', alignment: 'right' }
                ])
              })
              allRows.push([
                { text: vm.$t('sitePreference.total'), style: 'td', alignment: 'right', bold: true, colSpan: 6 },
                { },
                { },
                { },
                { },
                { },
                { text: vm.$n(monthItem.total_info.total_amount), style: 'td', alignment: 'right' }
              ])
            })
          } else {
            allRows.push([
              { text: vm.$t('globalTrans.noDataFound'), style: 'th', alignment: 'center', bold: true, colSpan: 7 },
              {},
              {},
              {},
              {},
              {},
              {}
            ])
          }
          allRows.push([
            { text: vm.$t('sitePreference.total'), style: 'td', alignment: 'right', bold: true, colSpan: 6 },
            { },
            { },
            { },
            { },
            { },
            { text: vm.$n(serviceItem.total_info.total_amount), style: 'td', alignment: 'right' }
          ])
          if (vm.listData.length === (serviceIndex + 1)) {
            allRows.push([
              { text: vm.$t('sitePreference.grandTotal'), style: 'td', alignment: 'right', bold: true, colSpan: 6 },
              { },
              { },
              { },
              { },
              { },
              { text: vm.$n(grandTotalInfo.total_amount), style: 'td', alignment: 'right' }
            ])
          }
          pdfContent.push({
            table: {
              headerRows: 1,
              widths: ['7%', '9%', '*', '*', '12%', '*', '*'],
              // widths: '*',
              body: allRows
            }
          })
        })
      } else {
        pdfContent.push({ text: vm.$t('globalTrans.noDataFound'), style: 'fertilizer', alignment: 'center' })
      }
      // const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
      var docDefinition = {
      content: pdfContent,
      pageSize: 'A4',
      pageOrientation: 'landscape',
      // watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
      styles: {
        th: {
          fontSize: (i18n.locale === 'bn') ? 11 : 10,
          margin: [3, 3, 3, 3]
        },
        td: {
          fontSize: (i18n.locale === 'bn') ? 11 : 10,
          margin: [3, 3, 3, 3]
        },
        search: {
          fontSize: (i18n.locale === 'bn') ? 10 : 8,
          margin: [3, 3, 3, 3]
        },
        fertilizer: {
          margin: [5, 0, 0, 5]
        },
        header: {
          fontSize: 12,
          margin: [0, 0, 0, 4]
        },
        header2: {
          fontSize: 14,
          margin: [0, 10, 0, 20]
        },
        headerPort1: {
          fontSize: 10,
          margin: [0, 20, 0, 5]
        },
        headerPort: {
          fontSize: 10,
          margin: [0, 4, 0, 15]
        },
        krishi: {
          margin: [0, -5, 0, 15],
          alignment: 'center'
        },
        header3: {
          fontSize: 9,
          margin: [0, 0, 0, 0]
        },
        org: {
          fontSize: 13,
          bold: true,
          margin: [0, -25, 10, 5]
        },
        hh: {
          fontSize: 13,
          bold: true,
          margin: [10, 10, 25, 20]
        },
        address: {
          fontSize: 11,
          margin: [0, 0, 0, 5]
        },
        tableSubHead: {
          margin: [0, 5, 0, 15]
        },
        header1: {
          fontSize: 15,
          margin: [5, 5, 5, 5]
        }
      }
    }
    pdfMake.createPdf(docDefinition, null, null, null).print()
  } catch (error) {
    if (error) {}
  }
  Store.commit('mutateCommonProperties', {
    loading: false
  })
}
export default {
  exportPdfDetails
}
